.table__row_table {
    background-color: var(--secondary) !important;
    width: 100% !important;
    padding: 15px !important;
    border-radius: 10px !important;
}

.table__form_filter {
    display: flex !important;
    justify-content: flex-end !important;
}

.table__form_filter .ant-form-item {
    margin-bottom: 0px !important;
}

.table__form_filter_input {
    border-radius: 15px !important;
}

.table__title {
    height: 50% !important;
    display: flex !important;
    align-items: flex-end !important;
    color: var(--primary) !important;
    font-size: 16px !important;
}

.table__summary {
    height: 50% !important;
    display: flex !important;
    align-items: flex-start !important;
    font-size: 11px !important;
}

.table__summary_qtd {
    color: var(--link) !important;
}

.table__row_buttons {
    padding: 15px 0px !important;
    justify-content: flex-end !important;
}

.table__row_button {
    border-radius: 15px !important;
}

.table__ table th  {
    text-align: center !important;
}

.table__actions {
    display: flex !important;
    justify-content: center !important;
}

.table__actions_button_view,
.table__actions_button_edit,
.table__actions_button_delete,
.table__actions_button_config,
.table__actions_button_recover {
    background-color: #000 !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    padding: 5.5px 6.9px !important;
    color: #FFF !important;
}

.table__actions_button_view:hover,.table__actions_button_view:focus,
.table__actions_button_edit:hover,.table__actions_button_edit:focus,
.table__actions_button_delete:hover,.table__actions_button_delete:focus,
.table__actions_button_config:hover, .table__actions_button_config:focus,
.table__actions_button_recover:hover, .table__actions_button_recover:focus {
    color: #FFF !important;
}

.table__actions_button_view {
    background-color: rgb(58, 30, 219) !important;
}
.table__actions_button_view:hover, .table__actions_button_view:focus {
    background-color: rgb(51, 28, 180) !important;
}

.table__actions_button_edit {
    background-color: rgb(219, 106, 30) !important;
}
.table__actions_button_edit:hover, .table__actions_button_edit:focus {
    background-color: rgb(197, 94, 25) !important;
}

.table__actions_button_delete {
    background-color: rgb(219, 43, 30) !important;
}
.table__actions_button_delete:hover, .table__actions_button_delete:focus {
    background-color: rgb(172, 34, 24) !important;
}

.table__actions_button_config {
    background-color: rgb(30, 219, 62) !important;
}
.table__actions_button_config:hover, .table__actions_button_config:focus {
    background-color: rgb(22, 165, 46) !important;
}

.table__actions_button_recover {
    background-color: rgb(126, 126, 126) !important;
}
.table__actions_button_recover:hover, .table__actions_button_recover:focus {
    background-color: rgb(100, 100, 100) !important;
}
