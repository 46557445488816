.layout_page404 {
    background-image: linear-gradient(to bottom right, var(--primary), var(--azul01));
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.layout_page404_row {
    background-color: var(--secondary);
    padding: 15px 25px;
    width: 95%;
    border-radius: 15px;
    justify-content: center;
    text-align: center;
}

.layout_page404_row_title {
    font-size: 140px !important;
    font-weight: 900 !important;
    color: var(--primary) !important;
    margin-bottom: 0px !important;
    width: 100%;
}

.layout_page404_row_subtitle {
    font-size: 25px !important;
    text-transform: uppercase !important;
    color: var(--pret01) !important;
    margin-bottom: 0px !important;
    width: 100%;
}

.layout_page404_row_text {
    font-size: 18px !important;
    color: var(--pret01) !important;
    margin-bottom: 15px !important;
    width: 100%;
}

.layout_page404_row_button {
    background-color: var(--primary) !important;
    border: none !important;
    border-radius: 25px !important;
    width: 130px;
    text-transform: uppercase !important;
    color: var(--secondary) !important;
    margin-bottom: 30px;
}

@media (min-width: 700px) {
    .layout_page404_row {
        width: 80%;
    }
}

@media (min-width: 900px) {
    .layout_page404_row {
        width: 70%;
    }
}