.layout_register {
    background-image: linear-gradient(to bottom right, var(--primary), var(--azul01));
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.layout_register_row {
    background-color: var(--secondary);
    padding: 15px 25px;
    width: 60%;
    border-radius: 15px;
}

.register_form_input {
    height: 40px !important;
    border-radius: 15px !important; 
}
.register_form_title {
    color: var(--primary) !important;
    font-weight: 900 !important;
    font-size: 20px !important;
    text-align: center !important;
    margin-bottom: 10px !important;
}

.register_form {
    width: 100% !important;
}

.register_form_button {
    background-color: var(--primary) !important;
    border: none !important;
    height: 40px !important;
    border-radius: 15px !important; 
    width: 100%;
}

.register_form_button:hover {
    opacity: 0.9 !important;
}

.layout_register_row_col {
    display: flex !important;
    justify-content: center;
    align-items: center;
}

@media (max-width: 900px) {
    .layout_register_row {
        width: 90% !important;
    }
}
