.form_buttons {
    border-radius: 25px !important;
}

.form_buttons.primary {
    background-color: var(--primary) !important;
    border: none !important;
}

.form_buttons.primary:hover {
    opacity: 0.9;
    box-shadow: var(--primary) 0px 0px 10px;
}

.form_buttons.danger {
    background-color: #FF0000 !important;
    border: none !important;
}

.form_buttons.danger:hover {
    opacity: 0.9;
    box-shadow: #FF0000 0px 0px 10px;
}

.form_buttons.default:hover {
    border-color: var(--primary) !important;
    color: var(--primary) !important;
    box-shadow: var(--primary) 0px 0px 10px;
}
