@import '~antd/dist/antd.css';

:root {
  /* CORES PRINCIPAIS */
  --primary: #201DEA;
  --secondary: #ffffff;
  /* CORES SECUNDARIAS */
  --azul01: #2523a0;
  --azul02: #0f059c;
  --azul03: #acadf7;
  --azul04: #5f5dda;
  --azul05: #130f40;
  --roxo01: #f3ebf5;
  --roxo02: #f0daf5;
  --roxo03: #e4bbff;
  --roxo04: #ea98fe;
  --roxo05: #da61ff;
  --cinz01: #a6a29f;
  --cinz02: #3f3e3e;
  --pret01: #000000;
  --bran01: #f0f2f5;
}

.ant-drawer-content {
  border-radius: 20px 0px 0px 20px !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
