.layout_login {
    background-image: linear-gradient(to bottom right, var(--primary), var(--azul01));
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.layout_login_row {
    background-color: var(--secondary);
    padding: 15px 25px;
    width: 80%;
    border-radius: 15px;
}

.login_form_input {
    height: 50px !important;
    border-radius: 15px !important; 
}

.login_form_button {
    background-color: var(--primary) !important;
    border: none !important;
    height: 50px !important;
    border-radius: 15px !important; 
    width: 100%;
}

.login_form_button:hover {
    opacity: 0.9 !important;
}

.login_form_forgot {
    color: var(--primary);
    float: right;
}

.login_form_forgot:hover {
    color: var(--primary);
    opacity: 0.9;
}

@media (min-width: 500px) {
    .layout_login_row {
        width: 45%;
    }
}

@media (min-width: 700px) {
    .layout_login_row {
        width: 35%;
    }
}

@media (min-width: 900px) {
    .layout_login_row {
        width: 25%;
    }
}