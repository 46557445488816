.control_layout {
    height: 100vh !important;
    background-color: var(--secondary) !important;
    overflow: hidden !important;
}

.control_layout_sider {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}

.control_layout_sider.active {
    flex: 0 0 100px !important;
    max-width: 100px !important;
    min-width: 100px !important;
    width: 100px !important;
    background-color: var(--secondary) !important;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.26) 1px 1px 5px !important;
}

.control_layout_sider > div {
    display: none!important;
}
.control_layout_sider.active > div {
    display: block !important;
}

.control_layout_content {
    padding: 15px;
    background-color: var(--bran01) !important;
}

.control_layout_outlet {
    overflow-y: scroll !important;  
    height: 100vh !important;  
    padding-bottom: 100px !important;
}

.control_layout_content.active {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
}

.control_layout_sider_static {
    flex: 0 0 0px !important;
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}

.control_layout_sider_static.active {
    flex: 0 0 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
    width: 300px !important;
    background-color: var(--secondary) !important;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.26) -1px -1px 5px !important;
}

.control_layout_sider_static > div {
    display: none!important;
}
.control_layout_sider_static.active > div {
    display: block !important;
}

.control_layout_header {
    background-color: var(--bran01) !important;
    padding: 0px !important;
    height: 40px !important;
    line-height: 40px !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 8px !important;
}

.control_layout_header_menu {
    border-radius: 50% !important;
    z-index: 999 !important;
    width: 30px !important;
    height: 30px !important;
    padding: 5.5px 6.9px !important;
    color: black !important;
}

.sidebar_perfil {
    display: flex !important;
    align-items: center !important;
}

@media (max-width: 700px) {

    .control_layout_header_menu {
        z-index: 996;
    }

    .control_layout_sider, .control_layout_sider_static {
        position: absolute !important;
        padding-top: 20px !important;
        z-index: 998;
        height: 100vh;
    }

    .control_layout_sider_static {
        right: 0 !important;
    }

    .control_layout_sider_static.active {
        flex: 0 0 300px !important;
        max-width: 300px !important;
        min-width: 300px !important;
    }
    
    .control_layout_sider.active, .control_layout_sider_static.active {
        background-color: var(--secondary) !important;
        padding: 10px;
    }

    .control_layout_sider.active{padding-top: 50px !important;}

}