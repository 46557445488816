.sidebar_col {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.sidebar_button {
    height: 40px !important;
    width: 40px !important;
    border-radius: 8px !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: var(--primary) !important;
    border: none !important;
}

.sidebar_button:hover {
    opacity: 0.9;
    box-shadow: var(--primary) 0px 0px 10px;
}