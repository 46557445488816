.card_KPI {
    padding: 0px !important;
    border-radius: 8px !important;
}

.card_KPI .ant-card-body {
    padding: 0px !important;
    border-radius: 8px !important;
    display: flex !important;
    flex-direction: row !important;
}

.KPI {
    background-image: linear-gradient(to bottom right, var(--primary), var(--azul01));
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px !important;
}

.KPI_dados {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}  

.KPI_dados_desc {
    color: var(--primary);
}

.KPI_dados_valor {
    font-size: 22px;
}