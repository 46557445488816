.add_button_screen_col {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
}

.add_button_screen {
    text-transform: uppercase !important;
    font-size: 11px !important;
    background-color: var(--primary) !important;
    border: none !important;
    border-radius: 15px !important;
}

.add_button_screen:hover {
    opacity: 0.9;
    box-shadow: var(--primary) 0px 0px 10px;
}