.category_item .ant-card .ant-card-body  {
    display: flex !important;
    flex-direction: row !important;
    padding: 0px !important;
}

.category_item .ant-card .ant-card-body .ant-card-meta-detail {
    padding: 15px!important;
    text-align: justify !important;
}

@media (max-width: 700px) {
    .category_item .ant-card .ant-card-body  {
        flex-direction: column !important;
    }
    .category_item .ant-card .ant-card-body .image_category {
        width: 100% !important;
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
}